import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Unsubscribe() {
  const { subscriberId } = useParams(); 
    const unsubscribe = async (e) => {
      console.log(subscriberId)   
      try {
        const response = await fetch('/api/unsubscribe', {
          method: 'PUT',
          headers: {
           'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subscriberId }),
        });        
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error); // Log any errors
      }
    };
    useEffect(() => {
      // Run handleSubmit once on the first page load

      unsubscribe();
      console.log("here!")
    },); // Empty dependency array ensures it runs only once on the first load
  

  return (
    <div>
      <h1>Unsubscribe Successful. We're sorry to see you go.</h1>      
    </div>
  );
}

export default Unsubscribe;