import React, { useState } from 'react';
import "../styles/landing.css";

const BeehiivSubscription = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail('');
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
         'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);      
      } else {
        setIsSubscribed(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);      
    }
  };

  return (
    <div className='input-container'>
        <form className="form-floating" onSubmit={handleSubmit}>
          <input
            type="email"
            className="form-control" 
            id="floatingInput"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="floatingInput">Email address</label>  
          
          <a
          href='/'
          className={`button ${isSubscribed ? 'button-subscribed' : ''}`}
          onClick={handleSubmit}
          disabled={isSubscribed}>
          <span>{isSubscribed ? 'Successful' : 'Subscribe'}</span>
        </a>
      </form> 
    </div>
  );
};

export default BeehiivSubscription;
