import React from 'react';
import { default as Marquee } from 'react-fast-marquee';

import slider1 from "../images/slider/slider-1.svg";
import slider2 from "../images/slider/slider-2.svg";
import slider3 from "../images/slider/slider-3.svg";
import slider4 from "../images/slider/slider-4.svg";
import slider5 from "../images/slider/slider-5.svg";
import slider6 from "../images/slider/slider-6.svg";
import slider7 from "../images/slider/slider-7.svg";
import slider8 from "../images/slider/slider-8.svg";
import slider9 from "../images/slider/slider-9.svg";
import slider10 from "../images/slider/slider-10.svg";
import slider11 from "../images/slider/slider-11.svg";
import slider12 from "../images/slider/slider-12.svg";
import slider13 from "../images/slider/slider-13.svg";
import slider14 from "../images/slider/slider-14.svg";
import slider15 from "../images/slider/slider-15.svg";
import slider16 from "../images/slider/slider-16.svg";

import "../styles/slider.css";

export default function MarqueeBanner() {
  const companyLogos = [slider1, slider2, slider3, slider4, slider5,
                        slider6, slider7, slider8, slider9, slider10, 
                        slider11, slider12, slider13, slider14, slider15, slider16];

  return (
    <div className="marquee-container">
      <Marquee speed={25} gradient={false} >
        {companyLogos.map((logo, index) => (
          <img key={index} src={logo} alt={`company-logo-${index}`} className="company-logo" />
        ))}
      </Marquee>
    </div>
  );
}

