import React from 'react';
import "../styles/landing.css";
import logo from "../images/logo.svg";
import MarqueeBanner from "./slider.js";
import BeehiivSubscription from './beehiv.js';

export default function Landing() {

  return (
    <div className="landing-page">
      <div className="content">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="text-container">
          <div className='box'>
            <h2 className="title">
              Land your dream job with insider tips from college students who've mastered tech recruiting!
            </h2>            
            <p className="subtitle">
              Read by 10,000+ aspiring Software Engineers, Product Managers, Data Scientists, and Designers!
            </p>
          </div>
          <BeehiivSubscription />
          
        </div>
      </div>
      <div className="slider-wrapper">
        <MarqueeBanner />
      </div>
    </div>
  );
}
