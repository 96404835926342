import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from "./components/landing.js";
import Unsubscribe from "./components/unsubscribe.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/subscribe" element={<Landing />} />
        <Route path="/subscribe/:subscriberId/*" element={<Unsubscribe />} />
      </Routes>
    </Router>
  );
}

export default App;